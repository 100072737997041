import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const coursesSlider  = new Swiper('.courses-slider',{
  direction: 'horizontal',
  slidesPerView: 1,
  autoHeight: false,
  loop: true,
  spaceBetween: 55,
  speed: 800,
  autoplay: {
    delay: 4000,
  },
  navigation:{
    nextEl: ".courses-swiper-button-next",
    prevEl: ".courses-swiper-button-prev",
    disabledClass: 'disabled_swiper_button'
  },
  breakpoints: {
    750: {
      slidesPerView: 2,
    },
    1100: {
      slidesPerView: 3,
    }

  }
})
