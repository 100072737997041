import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const eventsSlider  = new Swiper('.catalog-slider',{
  direction: 'horizontal',
  slidesPerView: 1,
  autoHeight: false,
  loop: true,
  spaceBetween: 60,
  speed: 800,
  autoplay: {
    delay: 4000,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: 'true',
  },
  breakpoints: {
    550: {
      slidesPerView: 2,
    },
    1000: {
      slidesPerView: 3,
    },
    1300: {
      slidesPerView: 4,
    }

  }
})
