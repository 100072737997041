const $input = $('input, textarea');

$input.on('focus', function () {
  $(this).closest('.gfield').find('label').addClass('away');

  console.log($(this).attr('type'));
  console.log($(this).val().length);
});

$input.on('blur', function () {
  if (!$(this).val()) {
    $(this).closest('.gfield').find('label').removeClass('away');
  }
});

$('input[type="file"]').on('focus', function () {
  $(this).closest('.gfield').find('label').addClass('focus');
});
$('input[type="file"]').on('blur', function () {
  $(this).closest('.gfield').find('label').removeClass('focus');
});

$('footer .gfield--type-html').on('click', ()=> {
  $('.gform_footer .gform_button').click()
})

$(document).on('select2:open', 'select', function() {
  $('.select2-search--dropdown .select2-search__field').attr('placeholder', 'Type to search…');
});
