import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const eventsSlider  = new Swiper('.swiper-testimonials',{
  direction: 'vertical',
  slidesPerView: 1,
  autoHeight: true,
  loop: true,
  spaceBetween: 10,
  speed: 800,
  autoplay: {
    delay: 4000,
  },
})
