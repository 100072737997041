import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const teamSlider  = new Swiper('.team-slider',{
  direction: 'horizontal',
  slidesPerView: 1,
  autoHeight: false,
  loop: true,
  spaceBetween: 0,
  speed: 800,
  autoplay: {
    delay: 4000,
  },
  navigation:{
    nextEl: ".team-swiper-button-next",
    prevEl: ".team-swiper-button-prev",
    disabledClass: 'disabled_swiper_button'
  },
  breakpoints: {
    700: {
      slidesPerView: 2,
    },
    1000: {
      slidesPerView: 3,
    }

  }
})
