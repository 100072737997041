import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const eventsSlider  = new Swiper('.events-slider',{
  direction: 'horizontal',
  slidesPerView: 1,
  watchOverflow: true,
  autoHeight: false,
  loop: true,
  spaceBetween: 40,
  speed: 800,
  // autoplay: {
  //   delay: 4000,
  // },
  navigation:{
    nextEl: ".events-swiper-button-next",
    prevEl: ".events-swiper-button-prev",
    disabledClass: 'disabled_swiper_button'
  },
  on: {
    init: function() {
      checkSliderActive(this);
    },
    resize: function() {
      checkSliderActive(this);
    }
  },
  breakpoints: {
    550: {
      slidesPerView: 2,
    },
    1000: {
      slidesPerView: 3,
    },
    1300: {
      slidesPerView: 4,
    }

  }
})

function checkSliderActive(swiperInstance) {
  const element = document.querySelector('.events-slider');
  const numOfSlides = swiperInstance.wrapperEl.querySelectorAll('.swiper-slide').length;
  const activeClass = 'slider-active';

  let slidesPerView = swiperInstance.params.slidesPerView;

  if (swiperInstance.currentBreakpoint) {
    slidesPerView = swiperInstance.params.breakpoints[swiperInstance.currentBreakpoint].slidesPerView || slidesPerView;
  }

  if (numOfSlides > slidesPerView) {
    element.classList.add(activeClass);
  } else {
    element.classList.remove(activeClass);
  }
}
