import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const ctaSlider  = new Swiper('.cta-slider',{
  direction: 'horizontal',
  slidesPerView: 1,
  autoHeight: false,
  loop: true,
  spaceBetween: 60,
  speed: 800,
  autoplay: {
    delay: 4000,
  },
  navigation:{
    nextEl: ".cta-swiper-button-next",
    prevEl: ".cta-swiper-button-prev",
    disabledClass: 'disabled_swiper_button'
  },
  breakpoints: {
    500: {
      slidesPerView: 2,
    },
    1000: {
      slidesPerView: 3,
    },
    1300: {
      slidesPerView: 4,
    }

  }
})
